<template>
  <ul
    class="mm-breadcrumbs"
    itemscope
    itemtype="https://schema.org/BreadcrumbList"
  >
    <li
      v-for="(item, index) in internalItems"
      :key="index"
      class="mm-breadcrumbs__item-wrapper"
    >
      <BarsBreadcrumbsItem
        :item="item"
        :position="++index"
        @child-item-click="onChildItemClick($event, index)"
        @sub-item-click="onSubItemClick($event)"
        @link-item-click="onLinkItemClick($event)"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import BarsBreadcrumbsItem from './BreadcrumbsItem';
import { IBreadcrumbsItem, IBreadcrumbChildItemClick, IBreadcrumbItemClick } from '../../models/breadcrumbs.model';
import { cloneDeep } from 'lodash-es';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  // Массив элементов вида IBredcrumbsItem
  items: Array<IBreadcrumbsItem>;
}>();

const emit = defineEmits<{
  (e: 'itemClick', value: IBreadcrumbItemClick): void;
  (e: 'subItemClick', value: IBreadcrumbsItem): void;
  (e: 'linkItemClick', value: IBreadcrumbsItem): void;
}>();

const watchSubscription = new WatchSubscription();
const internalItems = ref<Array<IBreadcrumbsItem>>([]);

initInternalItems();

function initInternalItems(): void {
  internalItems.value = cloneDeep(props.items);
}

function onChildItemClick(item: IBreadcrumbChildItemClick, index: number): void {
  internalItems.value = [...internalItems.value.slice(0, index + 1), item.child];
  emit('itemClick', { ...item, itemIndex: index });
}

function onSubItemClick(item: IBreadcrumbsItem): void {
  emit('subItemClick', item);
}

function onLinkItemClick(item: IBreadcrumbsItem): void {
  emit('linkItemClick', item);
}

watchSubscription.add(
  watch(
    () => props.items,
    initInternalItems,
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
.mm-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  &__item-wrapper {
    flex-shrink: 0;
    list-style-type: none;
  }

  &__item-wrapper:last-child {
    :deep(.mm-breadcrumb a) {
      border: none;
    }
  }
}
</style>
